<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Videos</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-row v-show="showSearch" align="center" style="max-width: 300px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search Videos"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <template v-else>
        <v-select
          class="ml-10"
          hide-details
          dense
          item-text="label"
          item-value="value"
          v-model="defaultFilter"
          :items="filterBy"
          style="max-width: 200px"
          @change="filterVideo"
        />

        <v-btn
          depressed
          class="ml-2"
          :color="sortOrder ? 'primary' : ''"
          @click="
            sortOrder = true
            filterVideo()
          "
        >
          <v-icon>{{ icons.arrowUp }}</v-icon>
        </v-btn>
        <v-btn
          depressed
          class="ml-2"
          :color="!sortOrder ? 'primary' : ''"
          @click="
            sortOrder = false
            filterVideo()
          "
        >
          <v-icon>{{ icons.arrowDown }}</v-icon>
        </v-btn>

        <v-btn class="ml-5" icon @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </template>
    </v-app-bar>

    <div
      class="mx-4"
      id="venue-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="d-flex my-6">
        <v-spacer></v-spacer>
        <template v-if="!loading">
          <v-btn
            color="primary"
            @click="$router.push({ name: 'videos.create' })"
            ><v-icon left dark> {{ icons.add }} </v-icon>Add Videos</v-btn
          >
        </template>
      </div>
      <v-simple-table>
        <thead>
          <tr>
            <th width="20%" class="text-left">Name</th>
            <th width="20%" class="text-left">File Name</th>
            <th width="15%" class="text-left">Duration</th>
            <th width="15%" class="text-left">Size</th>
            <th width="15%" class="text-left">Mime Type</th>
            <th width="15%" class="text-left">Date Created</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="video in videos"
            :key="video.id"
            class="clickable"
            @click="
              $router.push({
                name: 'videos.details',
                params: { id: video.id },
              })
            "
          >
            <td>{{ video.name }}</td>
            <td>{{ video.file_name }}</td>
            <td>
              {{
                String(video.duration_minutes).padStart(2, '0') +
                ':' +
                String(video.duration_seconds).padStart(2, '0')
              }}
            </td>
            <td>{{ formatBytes(video.size) }}</td>
            <td>{{ video.mime_type }}</td>
            <td>{{ video.addedDate }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiPlus,
  mdiArrowUp,
  mdiArrowDown,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'Videos',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      filter: {
        search: '',
      },
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        add: mdiPlus,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
      },
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
      exercise_category_id: '',
      defaultFilter: 'name',
      sortOrder: true,
      filterBy: [
        {
          value: 'title',
          label: 'Title',
        },
        {
          value: 'name',
          label: 'Name',
        },
        {
          value: 'size',
          label: 'Size',
        },
        {
          value: 'created_at',
          label: 'Date Created',
        },
      ],
    }
  },

  created() {
    this.clearVideoList()
  },

  computed: {
    ...mapState({
      videos: (state) => state.video.list,
      listMeta: (state) => state.video.listMeta,
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    },
  },

  mounted() {
    this.fetchVideos()
  },

  methods: {
    ...mapActions({
      getVideos: 'video/getVideos',
    }),

    ...mapMutations({
      clearVideoList: 'video/clearVideoList',
    }),

    pageChanged(page) {
      this.fetchVideos(page)
    },

    filterVideo(filterValue) {
      this.clearVideoList()
      this.fetchVideos(null, filterValue)
    },

    filterVideoCategory(value) {
      this.clearVideoList()
      this.fetchVideos(null, value)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetchVideos(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearVideoList()
      this.fetchVideos()

      setTimeout(() => {
        this.$refs.searchField.$refs.input.focus()
      }, 500)
    }, 1600),

    async fetchVideos(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`,
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }
      this.loading = true
      await this.getVideos(params)
      this.loading = false
    },

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },

    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
  },
}
</script>
